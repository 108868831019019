<template>
  <v-layout row wrap align-content-center>
    <pre>{{ defensor }}</pre>
      <v-flex :class="estilo">
          <span class="font-weight-bold">Tipo Registro : </span> 
          <v-chip :color="colorEstado('CIUDADANIA')">CIUDADANIA</v-chip>
      </v-flex>
       <v-flex v-if="defensor.estado" :class="estilo">
            <span class="font-weight-bold"> Estado del Registro : </span> 
            <v-chip :color="colorEstado(defensor.estado.nombre)">{{defensor.estado.nombre}}</v-chip>
      </v-flex>
  </v-layout>
</template>
<script>

export default {
  props:{
    estilo:{
      type:String,
      default:'lg4 sm4 xs12 text-center'
    },
    defensor:{
      type:Object,
      default:()=>{
        return{

        }
      }
    }
  },
  data(){
    return{
      loading:false
    }
  },  
  methods:{
      colorEstado(estado){
           if(estado=='MIGRACION'||estado=='NORMAL'||estado=='CIUDADANIA'){
           return 'primary lighten-3';   
          }
          if(estado=='NUEVO'){
           return 'primary justify';   
          }
          if(estado=='PENDIENTE'){
           return 'warning';   
          }
          if(estado=='CONCLUIDO'){
           return 'success';   
          }
          return 'default'
      }
  }
};
</script>

